/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%; }

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem; }

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef; }

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto; }
  .rc-cta img {
    width: 100%; }

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%; }

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%; }

/* rc-one-image */
.rc-one-image {
  width: 100%; }

.rc-one-image img {
  margin: 0 auto; }

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto; }

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto; }

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto; }

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .rc-video .thumb img,
  .rc-video-left .thumb img,
  .rc-video-right .thumb img {
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .rc-video .thumb::before,
  .rc-video-left .thumb::before,
  .rc-video-right .thumb::before {
    z-index: 1;
    content: '';
    display: block;
    width: 20%;
    padding-bottom: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../img/rc/rc-play.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center; }

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem; }
  .rc-issuu .issuuembed.issuu-isrendered {
    width: 100% !important; }

/* rc-attachments */
.rc-attachments span {
  font-size: 90%; }

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem; }
  .rc-form .file-upload input[type="file"] {
    display: none; }

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem; }
  .rc-gmap .map {
    width: 100%;
    height: 350px; }

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none; }
  .rc-sounds li {
    margin: 0 0 10px 0; }
    .rc-sounds li audio {
      max-width: 100%; }

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important; }

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%; }

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important; }

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .rc-vine iframe {
    margin-left: auto !important;
    margin-right: auto !important; }

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center; }
  .rc-facebook .fb_iframe_widget {
    max-width: 100%; }
    .rc-facebook .fb_iframe_widget span {
      max-width: 100%;
      overflow: hidden; }

/* social share */
.social-share {
  margin: 10px 0; }

.social-share ul:after {
  content: '';
  display: block;
  clear: both; }

.social-share ul {
  margin: 0;
  padding: 0; }

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none; }

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6; }

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px; }

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px; }

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px; }

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px; }

.social-share ul li a:hover {
  opacity: 1; }

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%; } }

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%; } }

/*
 *	CONTENT BLOCK OVERRIDES
 */
/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rc-video,
.rc-video-left,
.rc-video-right {
  /*.thumb::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(blue, 0.75);
	}*/ }

@charset "UTF-8";
/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/front/resources/plugins/fancybox/img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/front/resources/plugins/fancybox/img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/front/resources/plugins/fancybox/img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/*
 *	VARIABLES
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* FLOR */
/* 
 *	VARIABLES	
 */
/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	BOOTSTRAP ADDONS
 */
.col--stretch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: stretch;
  justify-content: stretch; }
  .col--stretch > div {
    -ms-flex-positive: 1;
    flex-grow: 1; }

.col--vcenter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center; }

.col--vbottom {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: end;
  justify-content: flex-end; }

/*
 *	BOOTSTRAP OVERRIDES
 */
.form-control,
.btn {
  font-size: 0.9rem; }

/* text */
h1, .h1 {
  color: #000;
  font-size: 2.2rem;
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold; }

h2, .h2 {
  color: #000;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold; }

h3, .h3 {
  font-size: 1.4rem;
  font-weight: bold;
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }

h4, .h4 {
  color: #F18B09;
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  font-size: 1.2rem; }

h5, .h5 {
  color: #F18B09;
  font-size: 1rem;
  font-weight: bold; }

h6, .h6 {
  color: #000;
  font-size: 0.9rem;
  text-transform: uppercase; }

a {
  color: #F18B09; }
  a:hover, a:focus {
    color: #F18B09; }

.text-primary {
  color: #F18B09 !important; }

/* buttons */
.btn {
  border-radius: 0;
  text-transform: uppercase;
  font-weight: bold;
  max-width: 100%;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  white-space: normal; }

.btn-primary {
  color: #fff !important;
  background-color: #F18B09;
  border-color: #F18B09; }
  .btn-primary:hover, .btn-primary.hover, .btn-primary:focus, .btn-primary.focus {
    background-color: #f7971d;
    border-color: #F18B09; }
  .btn-primary:active, .btn-primary.active, .btn-primary:active:hover, .btn-primary.active.hover, .btn-primary:active:focus, .btn-primary.active.focus {
    background-color: #d87d08;
    border-color: #F18B09; }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #F18B09;
    border-color: #F18B09; }
  .btn-primary.disabled:hover, .btn-primary:disabled:hover {
    background-color: #F18B09;
    border-color: #F18B09; }

/* nav */
.navbar {
  padding: 1rem 1.5rem;
  border-radius: 0;
  padding-bottom: 0; }

.navbar-nav .nav-link {
  padding: .325rem .425rem; }

.navbar-dark .navbar-nav .nav-link {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #393939;
  color: #F2F3F3;
  transition: color, border-color 0.2s ease-out; }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: #F2F3F3;
    border-color: #A1A1A1; }

.navbar-dark .navbar-nav .active .nav-link {
  color: #F18B09; }

.navbar-toggler {
  color: #FFF; }
  .navbar-toggler span {
    font-size: 0.9rem;
    text-transform: uppercase; }

.dropdown-menu {
  border-radius: 0; }

.bg-inverse {
  background-color: #000;
  background-image: linear-gradient(#3B3B3B, #000);
  background-image: url("../img/bg-pat.png"), linear-gradient(#3B3B3B, #000); }
  .bg-inverse .btn-primary {
    /* tab focus */
    /* hover */
    /* active */
    /* disabled */ }
    .bg-inverse .btn-primary:focus, .bg-inverse .btn-primary.focus, .bg-inverse .btn-primary:active:focus, .bg-inverse .btn-primary:active.focus, .bg-inverse .btn-primary.active:focus, .bg-inverse .btn-primary.active.focus {
      outline: none; }

.navbar-toggleable-md::after {
  content: none; }

/* forms */
.form-control {
  border-radius: 0; }
  .form-control:focus {
    border-color: #F18B09; }

/* cards */
.card {
  border-radius: 0; }

.card-img-top {
  border-radius: 0; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  color: #F18B09;
  text-transform: uppercase;
  font-weight: bold; }

.card-inverse .card-block {
  background-color: #000;
  background-image: linear-gradient(#3B3B3B, #000);
  background-image: url("../img/bg-pat.png"), linear-gradient(#3B3B3B, #000); }

.card-inverse .btn {
  font-weight: normal;
  text-transform: none;
  padding: .2rem .725rem;
  border-color: #393939;
  background-color: #000;
  transition: color 0.2s ease-out, border 0.2s ease-out; }
  .card-inverse .btn:hover, .card-inverse .btn.hover {
    border-color: #A1A1A1; }

/* breadcrumb */
.breadcrumb {
  margin-bottom: 0;
  background-color: transparent;
  text-transform: uppercase;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }
  .breadcrumb a {
    color: #A1A1A1; }
  .breadcrumb > .active {
    color: #F18B09; }
  .breadcrumb > li + li::before {
    color: #393939; }

/* table */
.table td, .table th {
  border-top: none; }

@font-face {
  font-family: 'fontello';
  src: url("/front/public/fonts/fontello/fontello.eot?60874568");
  src: url("/front/public/fonts/fontello/fontello.eot?60874568#iefix") format("embedded-opentype"), url("/front/public/fonts/fontello/fontello.woff2?60874568") format("woff2"), url("/front/public/fonts/fontello/fontello.woff?60874568") format("woff"), url("/front/public/fonts/fontello/fontello.ttf?60874568") format("truetype"), url("/front/public/fonts/fontello/fontello.svg?60874568#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?60874568#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-facebook-circled:before {
  content: '\f30d'; }

/* '' */
@font-face {
  font-family: 'octicons';
  src: url("/front/public/fonts/octicons/octicons.eot?#iefix") format("embedded-opentype"), url("/front/public/fonts/octicons/octicons.woff") format("woff"), url("/front/public/fonts/octicons/octicons.ttf") format("truetype"), url("/front/public/fonts/octicons/octicons.svg#octicons") format("svg");
  font-weight: normal;
  font-style: normal; }

/*

.octicon is optimized for 16px.
.mega-octicon is optimized for 32px but can be used larger.

*/
.octicon, .mega-octicon {
  font: normal normal normal 16px/1 octicons;
  display: inline-block;
  text-decoration: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.mega-octicon {
  font-size: 32px; }

.octicon-alert:before {
  content: '\f02d'; }

/*  */
.octicon-arrow-down:before {
  content: '\f03f'; }

/*  */
.octicon-arrow-left:before {
  content: '\f040'; }

/*  */
.octicon-arrow-right:before {
  content: '\f03e'; }

/*  */
.octicon-arrow-small-down:before {
  content: '\f0a0'; }

/*  */
.octicon-arrow-small-left:before {
  content: '\f0a1'; }

/*  */
.octicon-arrow-small-right:before {
  content: '\f071'; }

/*  */
.octicon-arrow-small-up:before {
  content: '\f09f'; }

/*  */
.octicon-arrow-up:before {
  content: '\f03d'; }

/*  */
.octicon-microscope:before,
.octicon-beaker:before {
  content: '\f0dd'; }

/*  */
.octicon-bell:before {
  content: '\f0de'; }

/*  */
.octicon-bold:before {
  content: '\f0e2'; }

/*  */
.octicon-book:before {
  content: '\f007'; }

/*  */
.octicon-bookmark:before {
  content: '\f07b'; }

/*  */
.octicon-briefcase:before {
  content: '\f0d3'; }

/*  */
.octicon-broadcast:before {
  content: '\f048'; }

/*  */
.octicon-browser:before {
  content: '\f0c5'; }

/*  */
.octicon-bug:before {
  content: '\f091'; }

/*  */
.octicon-calendar:before {
  content: '\f068'; }

/*  */
.octicon-check:before {
  content: '\f03a'; }

/*  */
.octicon-checklist:before {
  content: '\f076'; }

/*  */
.octicon-chevron-down:before {
  content: '\f0a3'; }

/*  */
.octicon-chevron-left:before {
  content: '\f0a4'; }

/*  */
.octicon-chevron-right:before {
  content: '\f078'; }

/*  */
.octicon-chevron-up:before {
  content: '\f0a2'; }

/*  */
.octicon-circle-slash:before {
  content: '\f084'; }

/*  */
.octicon-circuit-board:before {
  content: '\f0d6'; }

/*  */
.octicon-clippy:before {
  content: '\f035'; }

/*  */
.octicon-clock:before {
  content: '\f046'; }

/*  */
.octicon-cloud-download:before {
  content: '\f00b'; }

/*  */
.octicon-cloud-upload:before {
  content: '\f00c'; }

/*  */
.octicon-code:before {
  content: '\f05f'; }

/*  */
.octicon-comment-add:before,
.octicon-comment:before {
  content: '\f02b'; }

/*  */
.octicon-comment-discussion:before {
  content: '\f04f'; }

/*  */
.octicon-credit-card:before {
  content: '\f045'; }

/*  */
.octicon-dash:before {
  content: '\f0ca'; }

/*  */
.octicon-dashboard:before {
  content: '\f07d'; }

/*  */
.octicon-database:before {
  content: '\f096'; }

/*  */
.octicon-clone:before,
.octicon-desktop-download:before {
  content: '\f0dc'; }

/*  */
.octicon-device-camera:before {
  content: '\f056'; }

/*  */
.octicon-device-camera-video:before {
  content: '\f057'; }

/*  */
.octicon-device-desktop:before {
  content: '\f27c'; }

/*  */
.octicon-device-mobile:before {
  content: '\f038'; }

/*  */
.octicon-diff:before {
  content: '\f04d'; }

/*  */
.octicon-diff-added:before {
  content: '\f06b'; }

/*  */
.octicon-diff-ignored:before {
  content: '\f099'; }

/*  */
.octicon-diff-modified:before {
  content: '\f06d'; }

/*  */
.octicon-diff-removed:before {
  content: '\f06c'; }

/*  */
.octicon-diff-renamed:before {
  content: '\f06e'; }

/*  */
.octicon-ellipsis:before {
  content: '\f09a'; }

/*  */
.octicon-eye-unwatch:before,
.octicon-eye-watch:before,
.octicon-eye:before {
  content: '\f04e'; }

/*  */
.octicon-file-binary:before {
  content: '\f094'; }

/*  */
.octicon-file-code:before {
  content: '\f010'; }

/*  */
.octicon-file-directory:before {
  content: '\f016'; }

/*  */
.octicon-file-media:before {
  content: '\f012'; }

/*  */
.octicon-file-pdf:before {
  content: '\f014'; }

/*  */
.octicon-file-submodule:before {
  content: '\f017'; }

/*  */
.octicon-file-symlink-directory:before {
  content: '\f0b1'; }

/*  */
.octicon-file-symlink-file:before {
  content: '\f0b0'; }

/*  */
.octicon-file-text:before {
  content: '\f011'; }

/*  */
.octicon-file-zip:before {
  content: '\f013'; }

/*  */
.octicon-flame:before {
  content: '\f0d2'; }

/*  */
.octicon-fold:before {
  content: '\f0cc'; }

/*  */
.octicon-gear:before {
  content: '\f02f'; }

/*  */
.octicon-gift:before {
  content: '\f042'; }

/*  */
.octicon-gist:before {
  content: '\f00e'; }

/*  */
.octicon-gist-secret:before {
  content: '\f08c'; }

/*  */
.octicon-git-branch-create:before,
.octicon-git-branch-delete:before,
.octicon-git-branch:before {
  content: '\f020'; }

/*  */
.octicon-git-commit:before {
  content: '\f01f'; }

/*  */
.octicon-git-compare:before {
  content: '\f0ac'; }

/*  */
.octicon-git-merge:before {
  content: '\f023'; }

/*  */
.octicon-git-pull-request-abandoned:before,
.octicon-git-pull-request:before {
  content: '\f009'; }

/*  */
.octicon-globe:before {
  content: '\f0b6'; }

/*  */
.octicon-graph:before {
  content: '\f043'; }

/*  */
.octicon-heart:before {
  content: '\2665'; }

/* ♥ */
.octicon-history:before {
  content: '\f07e'; }

/*  */
.octicon-home:before {
  content: '\f08d'; }

/*  */
.octicon-horizontal-rule:before {
  content: '\f070'; }

/*  */
.octicon-hubot:before {
  content: '\f09d'; }

/*  */
.octicon-inbox:before {
  content: '\f0cf'; }

/*  */
.octicon-info:before {
  content: '\f059'; }

/*  */
.octicon-issue-closed:before {
  content: '\f028'; }

/*  */
.octicon-issue-opened:before {
  content: '\f026'; }

/*  */
.octicon-issue-reopened:before {
  content: '\f027'; }

/*  */
.octicon-italic:before {
  content: '\f0e4'; }

/*  */
.octicon-jersey:before {
  content: '\f019'; }

/*  */
.octicon-key:before {
  content: '\f049'; }

/*  */
.octicon-keyboard:before {
  content: '\f00d'; }

/*  */
.octicon-law:before {
  content: '\f0d8'; }

/*  */
.octicon-light-bulb:before {
  content: '\f000'; }

/*  */
.octicon-link:before {
  content: '\f05c'; }

/*  */
.octicon-link-external:before {
  content: '\f07f'; }

/*  */
.octicon-list-ordered:before {
  content: '\f062'; }

/*  */
.octicon-list-unordered:before {
  content: '\f061'; }

/*  */
.octicon-location:before {
  content: '\f060'; }

/*  */
.octicon-gist-private:before,
.octicon-mirror-private:before,
.octicon-git-fork-private:before,
.octicon-lock:before {
  content: '\f06a'; }

/*  */
.octicon-logo-gist:before {
  content: '\f0ad'; }

/*  */
.octicon-logo-github:before {
  content: '\f092'; }

/*  */
.octicon-mail:before {
  content: '\f03b'; }

/*  */
.octicon-mail-read:before {
  content: '\f03c'; }

/*  */
.octicon-mail-reply:before {
  content: '\f051'; }

/*  */
.octicon-mark-github:before {
  content: '\f00a'; }

/*  */
.octicon-markdown:before {
  content: '\f0c9'; }

/*  */
.octicon-megaphone:before {
  content: '\f077'; }

/*  */
.octicon-mention:before {
  content: '\f0be'; }

/*  */
.octicon-milestone:before {
  content: '\f075'; }

/*  */
.octicon-mirror-public:before,
.octicon-mirror:before {
  content: '\f024'; }

/*  */
.octicon-mortar-board:before {
  content: '\f0d7'; }

/*  */
.octicon-mute:before {
  content: '\f080'; }

/*  */
.octicon-no-newline:before {
  content: '\f09c'; }

/*  */
.octicon-octoface:before {
  content: '\f008'; }

/*  */
.octicon-organization:before {
  content: '\f037'; }

/*  */
.octicon-package:before {
  content: '\f0c4'; }

/*  */
.octicon-paintcan:before {
  content: '\f0d1'; }

/*  */
.octicon-pencil:before {
  content: '\f058'; }

/*  */
.octicon-person-add:before,
.octicon-person-follow:before,
.octicon-person:before {
  content: '\f018'; }

/*  */
.octicon-pin:before {
  content: '\f041'; }

/*  */
.octicon-plug:before {
  content: '\f0d4'; }

/*  */
.octicon-repo-create:before,
.octicon-gist-new:before,
.octicon-file-directory-create:before,
.octicon-file-add:before,
.octicon-plus:before {
  content: '\f05d'; }

/*  */
.octicon-primitive-dot:before {
  content: '\f052'; }

/*  */
.octicon-primitive-square:before {
  content: '\f053'; }

/*  */
.octicon-pulse:before {
  content: '\f085'; }

/*  */
.octicon-question:before {
  content: '\f02c'; }

/*  */
.octicon-quote:before {
  content: '\f063'; }

/*  */
.octicon-radio-tower:before {
  content: '\f030'; }

/*  */
.octicon-repo-delete:before,
.octicon-repo:before {
  content: '\f001'; }

/*  */
.octicon-repo-clone:before {
  content: '\f04c'; }

/*  */
.octicon-repo-force-push:before {
  content: '\f04a'; }

/*  */
.octicon-gist-fork:before,
.octicon-repo-forked:before {
  content: '\f002'; }

/*  */
.octicon-repo-pull:before {
  content: '\f006'; }

/*  */
.octicon-repo-push:before {
  content: '\f005'; }

/*  */
.octicon-rocket:before {
  content: '\f033'; }

/*  */
.octicon-rss:before {
  content: '\f034'; }

/*  */
.octicon-ruby:before {
  content: '\f047'; }

/*  */
.octicon-search-save:before,
.octicon-search:before {
  content: '\f02e'; }

/*  */
.octicon-server:before {
  content: '\f097'; }

/*  */
.octicon-settings:before {
  content: '\f07c'; }

/*  */
.octicon-shield:before {
  content: '\f0e1'; }

/*  */
.octicon-log-in:before,
.octicon-sign-in:before {
  content: '\f036'; }

/*  */
.octicon-log-out:before,
.octicon-sign-out:before {
  content: '\f032'; }

/*  */
.octicon-smiley:before {
  content: '\f0e7'; }

/*  */
.octicon-squirrel:before {
  content: '\f0b2'; }

/*  */
.octicon-star-add:before,
.octicon-star-delete:before,
.octicon-star:before {
  content: '\f02a'; }

/*  */
.octicon-stop:before {
  content: '\f08f'; }

/*  */
.octicon-repo-sync:before,
.octicon-sync:before {
  content: '\f087'; }

/*  */
.octicon-tag-remove:before,
.octicon-tag-add:before,
.octicon-tag:before {
  content: '\f015'; }

/*  */
.octicon-tasklist:before {
  content: '\f0e5'; }

/*  */
.octicon-telescope:before {
  content: '\f088'; }

/*  */
.octicon-terminal:before {
  content: '\f0c8'; }

/*  */
.octicon-text-size:before {
  content: '\f0e3'; }

/*  */
.octicon-three-bars:before {
  content: '\f05e'; }

/*  */
.octicon-thumbsdown:before {
  content: '\f0db'; }

/*  */
.octicon-thumbsup:before {
  content: '\f0da'; }

/*  */
.octicon-tools:before {
  content: '\f031'; }

/*  */
.octicon-trashcan:before {
  content: '\f0d0'; }

/*  */
.octicon-triangle-down:before {
  content: '\f05b'; }

/*  */
.octicon-triangle-left:before {
  content: '\f044'; }

/*  */
.octicon-triangle-right:before {
  content: '\f05a'; }

/*  */
.octicon-triangle-up:before {
  content: '\f0aa'; }

/*  */
.octicon-unfold:before {
  content: '\f039'; }

/*  */
.octicon-unmute:before {
  content: '\f0ba'; }

/*  */
.octicon-unverified:before {
  content: '\f0e8'; }

/*  */
.octicon-verified:before {
  content: '\f0e6'; }

/*  */
.octicon-versions:before {
  content: '\f064'; }

/*  */
.octicon-watch:before {
  content: '\f0e0'; }

/*  */
.octicon-remove-close:before,
.octicon-x:before {
  content: '\f081'; }

/*  */
.octicon-zap:before {
  content: '\26A1'; }

/* ⚡ */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

img {
  display: block;
  vertical-align: top; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  min-height: 100%;
  background: #fff;
  font-size: 15px;
  line-height: 1.4;
  font-family: "PT Sans", Helvetica, Arial, sans-serif; }

textarea {
  resize: vertical; }

/* miscellaneous */
.clear {
  clear: both;
  height: 0;
  font-size: 1px;
  line-height: 0px; }

.nizien {
  position: absolute;
  margin-left: -9999px; }

.hidden {
  display: none; }

.left {
  float: left !important; }

.right {
  float: right !important; }

/* box sizing */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

/* hide placeholder text on focus */
input:focus::-webkit-input-placeholder {
  color: transparent; }

input:focus:-moz-placeholder {
  color: transparent; }

input:focus::-moz-placeholder {
  color: transparent; }

input:focus:-ms-input-placeholder {
  color: transparent; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  min-height: 100%;
  font-family: "PT Sans", Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  background-color: #ECEBEC; }

/*
 *	UTILITIES
 */
.flex-stretch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }
  .flex-stretch > * {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 2px; }

/*
 *	GENERAL
 */
.panel {
  border: 1px solid #DADADA;
  background-color: #fff; }

.panel--pad {
  padding: 2rem; }

.with-errors {
  color: #d9534f; }

/*
 *	HEADER
 */
.header__banner {
  text-align: center; }
  .header__banner a {
    display: inline-block;
    max-width: 100%; }

.logo img {
  max-width: 100%; }

.call-us {
  margin-bottom: 0;
  color: #F18B09;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif; }
  .call-us a {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1;
    color: #000;
    transition: color 0.1s ease-out; }
    .call-us a:hover, .call-us a:focus {
      color: #F18B09;
      text-decoration: none; }

.search .form-control {
  padding: .325rem .425rem;
  line-height: 1.4;
  background-color: transparent;
  color: #fff;
  border-color: #393939; }
  .search .form-control:focus {
    border-color: #A1A1A1; }

.search .btn {
  padding: .325rem .425rem;
  line-height: 1.4; }

.search--page .form-control {
  color: #393939; }

.text-orange {
  color: #F18B09; }

.social {
  padding: 0;
  margin: 0;
  list-style-type: none; }
  .social li {
    float: left;
    font-size: 2em; }
    .social li a {
      color: #ffffff; }

/*
 *	ASIDE
 */
.nav--side {
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  text-transform: uppercase; }
  .nav--side > .nav-item > .nav-link {
    padding: 0.5em 0;
    border-bottom: 1px solid #DADADA;
    /*margin-bottom: 1em;*/ }
  .nav--side > .nav-item > .nav .nav-item {
    padding-left: 1rem; }
  .nav--side .nav-link {
    color: #A1A1A1; }
    .nav--side .nav-link:hover {
      color: #F18B09; }
  .nav--side .nav-link.active {
    color: #000; }

/*
 *	OFFER
 */
.offer {
  position: relative; }

.offer__overlay {
  z-index: 10;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  width: 44%;
  padding: 2rem;
  padding-right: 15%;
  color: #fff;
  background-image: url("/img/bg-offer.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100% 100%; }
  .offer__overlay .title a {
    color: #fff; }

.offer__slider {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .offer__slider::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("/img/bg-pat.png"); }
  .offer__slider img {
    width: 100%; }
  .offer__slider .carousel {
    width: 100%;
    height: 100%; }
  .offer__slider .carousel-inner {
    height: 100%; }
  .offer__slider .carousel-item {
    height: 100%; }
  .offer__slider .cover-img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; }
  .offer__slider .carousel-control {
    top: auto;
    right: auto;
    bottom: 60px;
    left: auto;
    opacity: 1; }
    .offer__slider .carousel-control span {
      text-shadow: none;
      background-color: #F18B09; }
    .offer__slider .carousel-control:hover span {
      background-color: #f7971d; }
    .offer__slider .carousel-control.right {
      right: 0; }
    .offer__slider .carousel-control.left {
      right: 45px; }

.offer-list {
  padding-left: 13px;
  /*list-style-position: inside;*/
  list-style-image: url("/img/bullet-arr.png"); }
  .offer-list li {
    padding: 4px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    .offer-list li:last-child {
      border-bottom: none; }
  .offer-list a {
    color: #fff;
    font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase; }

/*
 *	CONTACT CTA
 */
.contact {
  padding: 2rem 1rem;
  background-color: #F18B09;
  color: #fff;
  text-align: center;
  font-family: "Open Sans", Helvetica, Arial, sans-serif; }
  .contact p {
    margin-bottom: 0; }
  .contact a {
    color: #fff;
    text-decoration: underline; }
    .contact a:hover, .contact a:focus {
      color: #000; }

a.contact__phone {
  font-size: 1.8rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  transition: color 0.1s ease-out; }
  a.contact__phone:hover, a.contact__phone:focus {
    color: #000;
    text-decoration: none; }

aside .contact {
  font-size: 0.8rem; }

aside a.contact__phone {
  font-size: 1.3rem; }

/*
 *	NEWS
 */
.news {
  overflow: hidden;
  padding: 1.25rem;
  border-bottom: 1px solid #DADADA; }
  .news:last-of-type {
    border-bottom: none; }

.news__date {
  display: block;
  width: 65px;
  height: 65px;
  float: left;
  background-color: #F18B09;
  color: #fff;
  text-align: center;
  font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.1rem;
  line-height: 1.1; }

.news__text {
  overflow: hidden;
  padding-left: 1.25rem; }
  .news__text p {
    margin-bottom: 0; }
  .news__text a {
    color: #000;
    transition: color 0.1s ease-out;
    text-decoration: none; }
    .news__text a:hover, .news__text a:focus {
      color: #F18B09; }

/*
 *	BRANDS
 */
.brand {
  position: relative;
  width: 100%;
  padding-bottom: 70%;
  border: 1px solid #DADADA;
  background-color: #fff; }
  .brand a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center; }
  .brand img {
    margin: 0 auto;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%; }

/*
 *	SUBSCRIBE
 */
.subscribe {
  color: #fff;
  padding: 1rem 2rem 0;
  background-color: #000;
  background-image: linear-gradient(#3B3B3B, #000);
  background-image: url("../img/bg-pat.png"), linear-gradient(#3B3B3B, #000); }
  .subscribe .title {
    margin-bottom: 0;
    font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: #A1A1A1;
    font-size: 1.7rem;
    line-height: 1.3; }
  .subscribe .form-group,
  .subscribe .btn {
    width: 100%; }

/*
 *	HEADING
 */
.heading {
  position: relative; }

.heading__overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 40%;
  background-image: url("/img/bg-heading.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top right;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 4rem 0 2rem; }
  .heading__overlay .title {
    margin-bottom: 0;
    color: #fff; }
    .heading__overlay .title .lighter {
      color: rgba(255, 255, 255, 0.5); }

.heading__img {
  position: relative;
  max-height: 115px;
  overflow: hidden; }
  .heading__img::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("/img/bg-pat.png"); }
  .heading__img img {
    width: 100%;
    height: auto; }

/*
 *	DETAIL
 */
.lightbox {
  display: block;
  position: relative; }
  .lightbox::before {
    content: '+';
    color: #fff;
    background-color: #F18B09;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 28px;
    text-align: center;
    font-size: 28px;
    line-height: 28px;
    transition: background 0.15s ease-out; }
  .lightbox:hover::before {
    background-color: #000; }

.linklist {
  padding-left: 0;
  list-style: none;
  border-top: 1px solid #DADADA; }
  .linklist li {
    display: block;
    border-bottom: 1px solid #DADADA;
    padding: 0.4rem; }
  .linklist a {
    font-family: "PT Sans Narrow", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: #000; }
    .linklist a:hover, .linklist a:focus {
      color: #F18B09;
      text-decoration: none; }
  .linklist .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #000;
    color: #fff;
    margin-right: 5px;
    text-align: center; }
  .linklist .icon--red {
    background-color: #E02919;
    line-height: 20px; }

.linklist--single {
  border-top: none;
  text-align: center; }
  .linklist--single li {
    border-bottom: none; }

.list {
  padding-left: 1rem; }
  .list li {
    margin-bottom: 0.2rem; }

/*
 *	FOOTER
 */
.footer {
  border: 1px solid #DADADA;
  background-color: #fff;
  background-image: url("/img/bg-footer.png");
  background-repeat: no-repeat;
  background-position: bottom right; }
  .footer ul {
    margin-bottom: 0;
    list-style: none;
    padding-left: 0; }
  .footer li {
    margin-bottom: 0.2rem; }
  .footer a {
    color: #A1A1A1;
    font-weight: bold; }
    .footer a:hover, .footer a:focus {
      color: #393939; }
  .footer li {
    color: #A1A1A1;
    font-weight: bold; }

.footer__categories,
.footer__contact {
  padding: 2rem; }

.footer__categories .h2 a {
  color: #000; }

.footer__contact {
  color: #fff; }
  .footer__contact .h2 a {
    font-weight: bold; }
  .footer__contact a {
    color: #fff;
    font-weight: normal; }
  .footer__contact .h2 {
    color: #fff; }

/*
 * PRODUCT
 */
.product {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column; }

.product__image {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }
  .product__image a {
    display: block; }
  .product__image img {
    width: 100%; }

.product__info {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  color: #FFF;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .product__info .titles {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    /*
    word-break: break-all;
    word-break: break-word;
	*/
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }
    .product__info .titles h3 a {
      color: #FFF; }
  .product__info .btn-wrapper {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-item-align: end;
    align-self: flex-end;
    text-align: right; }
    .product__info .btn-wrapper .btn {
      color: #fff;
      font-weight: normal;
      text-transform: none;
      padding: .2rem .725rem;
      border-color: #393939;
      background-color: #000;
      transition: color 0.2s ease-out, border 0.2s ease-out; }
      .product__info .btn-wrapper .btn:hover, .product__info .btn-wrapper .btn.hover {
        color: #F18B09;
        border-color: #F18B09; }

/*
 *	IMPORTS
 */
/*
 *	WEBSHOP STANDAARD CSS
 */
/* 
 *	CART ITEM
 */
.ws-cart-item {
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0; }
  .ws-cart-item:last-of-type {
    border-bottom: 1px solid #f0f0f0; }

.ws-cart-item__img {
  -ms-flex: 1 0 17%;
  flex: 1 0 17%;
  max-width: 17%; }

.ws-cart-item__details {
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: 1rem; }

.ws-cart-item__actions {
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
  max-width: 33%;
  padding-left: 1rem; }
  .ws-cart-item__actions .price {
    margin-bottom: 1rem; }
    .ws-cart-item__actions .price::after {
      content: '';
      display: table;
      clear: both; }
  .ws-cart-item__actions .amount {
    float: left; }
    .ws-cart-item__actions .amount input {
      display: inline-block;
      margin-left: .5rem;
      width: 60px; }
  .ws-cart-item__actions .remove {
    clear: both; }

/*
*	CART LIST
*/
.ws-cart-dropdown {
  font-size: .8em;
  padding: 1rem; }
  .ws-cart-dropdown .ws-cart-list {
    max-width: 350px; }
    .ws-cart-dropdown .ws-cart-list .ws-cart-item p {
      margin-bottom: 5px; }
  .ws-cart-dropdown .ws-cart-item__actions .price {
    margin-bottom: 0; }
  .ws-cart-dropdown .ws-cart-item {
    padding: .5rem 0; }
  .ws-cart-dropdown .ws-cart-actions {
    padding: 1rem 0 0 0; }
    .ws-cart-dropdown .ws-cart-actions:after {
      content: '';
      display: block;
      clear: both; }
  .ws-cart-dropdown .ws-cart-total {
    padding: 10px;
    background-color: #F8F8F8; }

.custom-input {
  width: auto;
  display: inline-block;
  max-width: 60px;
  margin-left: .5rem;
  padding: .375rem .75rem .375rem .75rem;
  color: #55595c;
  vertical-align: middle;
  background: #fff; }

/*
 *	CART INFO
 */
.ws-info-box {
  padding: 1rem;
  background-color: #ddd; }
  .ws-info-box ul {
    padding-left: 0; }
  .ws-info-box li {
    list-style: none; }
  .ws-info-box .price {
    float: right; }
  .ws-info-box .table td {
    border-top: none; }
    .ws-info-box .table td:last-child {
      text-align: right; }
  .ws-info-box tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .ws-info-box tfoot tr:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* 
 *	CONTINUE
 */
.ws-continue {
  padding: 1rem 1rem .5rem;
  background-color: #ddd; }
  .ws-continue .btn {
    width: 100%; }

.ws-continue__logos {
  display: block;
  margin-bottom: 0;
  padding: 0;
  font-size: 0; }
  .ws-continue__logos li {
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: .5rem; }

/*
 *	PROGRESS
 */
.ws-progress .nav {
  width: 100%;
  display: -ms-flexbox;
  display: flex; }

.ws-progress .nav-item {
  -ms-flex: 1 0 25%;
  flex: 1 0 25%; }

.ws-progress .nav-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  background-color: #ddd; }
  .ws-progress .nav-link.active {
    font-weight: bold;
    font-style: italic; }

.ws-progress span.nav-link {
  color: rgba(0, 0, 0, 0.5); }

.ws-progress .nav-item:nth-child(2) .nav-link {
  background-color: #e5e5e5; }

.ws-progress .nav-item:nth-child(3) .nav-link {
  background-color: #ececec; }

.ws-progress .nav-item:nth-child(4) .nav-link {
  background-color: #f4f4f4; }

/*
 *	PAYMENT OPTIONS
 */
.ws-payment-options {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1; }
  .ws-payment-options li {
    display: inline-block;
    width: 50px;
    margin: 0 3px 5px 0; }
  .ws-payment-options img {
    max-width: 100%; }

.ws-pay-icon {
  width: 35px;
  vertical-align: middle; }

@media (max-width: 543px) {
  .ws-progress .nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ws-progress .nav-item {
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
  .ws-progress .nav-link {
    padding: .5rem; } }

/*
 *	Tijdelijke hacks en bugfixes
 */
/*
 *	CUSTOM RESPONSIVE CODE
 */
@media (max-width: 991px) {
  /* header */
  .call-us {
    font-size: .75rem; }
    .call-us a {
      font-size: 1.5rem; }
  /* offer */
  .offer__overlay {
    width: 100%;
    position: static;
    background-image: none;
    background-color: #F18B09; }
  .offer__slider {
    position: relative; }
    .offer__slider .cover-img {
      position: static;
      width: 100%;
      padding-bottom: 50%; }
  .offer-list {
    margin-bottom: 0; }
  /* footer */
  .footer {
    padding: 0; }
  .footer__categories {
    background: #FFF; }
  .footer__contact {
    background: #F18B09; } }

@media (max-width: 767px) {
  .call-us {
    text-align: right; } }

@media (max-width: 543px) {
  h1, .h1 {
    font-size: 1.8rem; }
  h2, .h2 {
    font-size: 1.4rem; }
  h3, .h3 {
    font-size: 1.1rem; }
  .col--logo {
    margin-bottom: 5px;
    text-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .logo {
    display: inline-block;
    width: 190px; }
  .call-us {
    text-align: center; }
    .call-us a {
      font-size: 1.2rem; }
  .panel--pad {
    padding: 1.25rem; }
  .subscribe,
  .footer__categories,
  .footer__contact {
    padding: 1.25rem; } }

@media (max-width: 300px) {
  .news__date {
    margin-bottom: 5px;
    display: inline-block;
    float: none;
    width: auto;
    height: auto;
    background-color: transparent;
    color: #F18B09; }
    .news__date br {
      display: none; }
  .news__text {
    padding-left: 0; } }

/*# sourceMappingURL=app.css.map */
